import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import Record from "../components/Record";
import lt_flag from "../img/lt_flag.png";
import ru_flag from "../img/ru_flag.png";
import microphone from "../img/microphone.png";

const Records = ({ recordsContainer }) => {
  const [state, setState] = useState({
    audioPlayer: {},
    currentTrackIndex: null,
    isPlaying: false,
    currSrc: "",
    isFinished: false
  });

  useEffect(() => {
    setState({
      audioPlayer: getAudioInstance(),
      currentTrackIndex: -1,
      isPlaying: false,
      currSrc: "",
      isFinished: false
    });
  }, []);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playerId, setPlayerId] = useState(0);

  function getAudioInstance() {
    let audio = new Audio();
    audio.addEventListener("timeupdate", e => {
      setCurrentTime(e.target.currentTime);
    });
    audio.addEventListener("loadedmetadata", e => {
      setDuration(e.target.duration);
    });
    audio.addEventListener("ended", e => {
      e.target.currentTime = 0;
      setState({
        isPlaying: false,
        currentTrackIndex: -1,
        currSrc: "",
        isFinished: true
      });
    });
    return audio;
  }

  function changeCurrentTime(clickPosition) {
    if(state.audioPlayer !== 'undefined') {
      try {
        let time = 0;
        if (clickPosition > 0.04) {
          time = clickPosition * state.audioPlayer.duration;
        }
        state.audioPlayer.currentTime = time;
      } catch(e) {
        console.log('Klaida');
      }
    }
  }

  function stopAudio() {
    state.audioPlayer.pause();
    setState({
      audioPlayer: state.audioPlayer,
      currentTrackIndex: state.currentTrackIndex,
      isPlaying: false,
      currSrc: state.currSrc,
      isFinished: false
    });
  }

  function startAudio(url, id, recordId, playNew) {
    url = "/" + url;
    let currTime = state.audioPlayer && state.currSrc === url ? state.audioPlayer.currentTime : 0;
    if(typeof state.audioPlayer === 'undefined') {
      state.audioPlayer = getAudioInstance();
    }
    let authorChanged = playNew && id !== playerId;
    setPlayerId(id);
    if (state.currSrc !== url) {
      state.currSrc = url;
      state.audioPlayer.src = url;
    }
    state.audioPlayer.currentTime = currTime;
    state.audioPlayer.play();
    setState({
      audioPlayer: state.audioPlayer,
      currentTrackIndex: authorChanged ? 0 : recordId,
      isPlaying: true,
      currSrc: url,
      isFinished: false
    });
  }

  var menLt = recordsContainer.menLt.map(function(record, index) {
    return (
      <Record
        key={index}
        id={1000 + index}
        playerId={playerId}
        record={record}
        startAudio={startAudio}
        stopAudio={stopAudio}
        currentTime={currentTime}
        duration={duration}
        changeCurrentTime={changeCurrentTime}
        parentState={state}
      />
    );
  });
  var womenLt = recordsContainer.womenLt.map(function(record, index) {
    return (
      <Record
        key={index}
        id={2000 + index}
        playerId={playerId}
        record={record}
        startAudio={startAudio}
        stopAudio={stopAudio}
        currentTime={currentTime}
        duration={duration}
        changeCurrentTime={changeCurrentTime}
        parentState={state}
      />
    );
  });
  var menRu = recordsContainer.menRu.map(function(record, index) {
    return (
      <Record
        key={index}
        id={3000 + index}
        playerId={playerId}
        record={record}
        startAudio={startAudio}
        stopAudio={stopAudio}
        currentTime={currentTime}
        duration={duration}
        changeCurrentTime={changeCurrentTime}
        parentState={state}
      />
    );
  });
  var womenRu = recordsContainer.womenRu.map(function(record, index) {
    return (
      <Record
        key={index}
        id={4000 + index}
        playerId={playerId}
        record={record}
        startAudio={startAudio}
        stopAudio={stopAudio}
        currentTime={currentTime}
        duration={duration}
        changeCurrentTime={changeCurrentTime}
        parentState={state}
      />
    );
  });
  return (
    <section
      className="section section--gradient"
      style={typeof window !== 'undefined' && window.innerWidth > 1200 ? {
        backgroundImage: `url(${microphone})`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `100% 34px`,
        backgroundSize: `200px`
      } : {}}
    >
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-3">
              <img src={lt_flag} className="flag-icon" alt="Lt" />
              <div className="tile">
                <span className="gender-title">
                  <FormattedMessage id="men" />
                </span>
              </div>
              {menLt}
            </div>
            <div className="column is-3">
              <div className="flag-icon" />
              <div className="tile">
                <span className="gender-title">
                  <FormattedMessage id="women" />
                </span>
              </div>
              {womenLt}
            </div>
            <div className="column is-3">
              <img
                src={ru_flag}
                className="flag-icon"
                alt="En"
              />
              <div className="tile">
                <span className="gender-title">
                  <FormattedMessage id="men" />
                </span>
              </div>
              {menRu}
            </div>
            <div className="column is-3">
              <div className="flag-icon" />
              <div className="tile">
                <span className="gender-title">
                  <FormattedMessage id="women" />
                </span>
              </div>
              {womenRu}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(Records);
