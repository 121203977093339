import React, {useState} from 'react'
import PropTypes from 'prop-types'
import img_play from '../img/button_blue_play.png'
import img_pause from '../img/button_blue_pause.png'
import img_list from '../img/list.png'
import img_list_item from '../img/list_item.png'
import { Slider, Direction } from 'react-player-controls'


const Record = ({ id, playerId, record, startAudio, stopAudio, currentTime, duration, changeCurrentTime, parentState }) => {
  const [isExpanded, setExpanded] = useState(0);
  const [hoverPosition, setHoverPosition] = useState(0);

  let data = record.url.split('$$');
  let path  = data[0];
  let count = parseInt(data[1]);
  let urls = [];

  for(let i = 1; i <= count; i++) {
    urls.push('audio/' + path + '/' + i + '.mp3');
  }
  var recordsList = urls.map((url, i)  =>
    <div key={'List'+id+playerId+Math.random()}>
      <span className="other-audio" onClick={() => play(url, i, false)}>
        <img src={img_list_item} style={i === parentState.currentTrackIndex && playerId === id ? {} : {filter: 'grayscale(100%)'}} alt="Dot"></img>
        {(i +1) + '. ' +  record.author}
      </span>
    </div>
  );


  function play(url, recordId, playNew) {
    startAudio(url, id, recordId, playNew);
  }

  function toggleRecords() {
    setExpanded(!isExpanded);
  }

  function zeroPadNumber(number) {
    return number < 10 ? '0' + number : number;
  }

  function formatTime(seconds) {
    if (typeof seconds === 'number' && seconds >= 0) {
        const m = Math.floor(seconds / 60);
        const s = Math.round(seconds) % 60;
        return zeroPadNumber(m) + ':' + zeroPadNumber(s);
    }
  }
  function ProgressBar() {
    if (id !== playerId || parentState.isFinished) {
      return null;
    }
    return (
      <div>
        <span className="timer">{formatTime(currentTime)}</span>
        <Slider
          style={{cursor: 'pointer', display: 'inline-block', margin: '0 4px'}}
          isEnabled={true}
          direction={Direction.HORIZONTAL}
          onIntent={intent => setHoverPosition(intent)} // current mouse position
          //onIntentStart={intent => console.log(`entered with mouse at ${intent}`)}
          onIntentEnd={() => setHoverPosition(0)} // ('left with mouse')
          onChange={newValue => changeCurrentTime(newValue)} // Click
          //onChangeStart={startValue => console.log(`started dragging at ${startValue}`)}
          //onChangeEnd={endValue => console.log(`stopped dragging at ${endValue}`)}
        >
          <div style={{position: 'relative', width: '100px', height: '8px', borderRadius: '4px', background: 'rgb(238, 238, 238)', transition: 'width 0.1s ease 0s'}}>
            <div style={{position: 'absolute', background: 'rgb(114, 214, 135)', borderRadius: '4px', top: '0px', bottom: '0px', left: '0px', width: (currentTime / duration * 100) + '%'}}></div>
            <div style={{position: 'absolute', background: 'rgba(111, 0, 0, 0.05)', borderRadius: '4px', top: '0px', bottom: '0px', left: '0px', width: (hoverPosition * 100) + '%'}}></div>
          </div>
        </Slider>
        <span className="timer">{formatTime(duration)}</span>
      </div>
    )
  }

  function List() {
    if (!isExpanded) {
      return null;
    }
    return recordsList;
  }
  function ListToggle() {
    if(urls.length > 1) {
      return (
      <button className="button-image" onClick={() => toggleRecords()}>
        <img src={img_list} className="player-image" alt="List"></img>
      </button>)
    }
    return null;
  }
  return (
    <div>
      <div className="audio-player">
        <span className="audio-author">{(id % 1000 + 1) + '. ' + record.author}</span>
        {playerId === id && parentState.isPlaying ? (
          <button className="button-image" onClick={() => stopAudio()} >
            <img src={img_pause} className="player-image" alt="Pause"></img>
          </button>
        ) : (
          <button className="button-image" onClick={() => play(urls[parentState.currentTrackIndex === -1 || playerId !== id ? 0 : parentState.currentTrackIndex], parentState.currentTrackIndex === -1 || playerId !== id ? 0: parentState.currentTrackIndex, true)}>
            <img src={img_play} alt="Play" className="player-image">
            </img>
          </button>
        )}
        <ListToggle key={'ListToggle'+Math.random()} />
        <ProgressBar key={'ListToggle'+Math.random()} />
      </div>
      <div>
        <List />
      </div>
    </div>
  )
}

Record.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      author: PropTypes.string,
    })
  ),
}

export default Record
